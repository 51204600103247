import { createMuiTheme } from '/web_modules/@material-ui/core.js';
import { colors, responsiveFontSizes } from '/web_modules/@material-ui/core.js';
export const NoteTakerTheme = responsiveFontSizes(createMuiTheme({
  palette: {
    primary: colors.purple,
    secondary: colors.green
  },
  typography: {
    fontFamily: '"Helvetica Neue", "Raleway"'
  },
  props: {
    MuiCard: {
      raised: true
    },
    MuiAppBar: {
      position: 'sticky'
    },
    MuiContainer: {
      disableGutters: true
    },
    MuiTypography: {
      gutterBottom: true
    }
  }
}));