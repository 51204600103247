var _class, _temp;

function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

import { observable, set, action } from '/web_modules/mobx.js';
import { NotesClient } from './notes-client.js';
import { LoadingBarState } from '../components/loading-bar.js';
import { LinkShrinkClient } from './link-shrink-client.js';

let _Notes = (_class = (_temp = class _Notes {
  constructor() {
    this.client = new NotesClient();
    this.links = new LinkShrinkClient();
    this.inProgress = {};
    this.state = observable({});
  }

  start(key, deferable) {
    if (!this.inProgress[key]) {
      this.inProgress[key] = true;
      LoadingBarState.transient.enqueue(async () => {
        await deferable().then(v => {
          this.state[key] = {
            timestamp: Date.now(),
            value: v
          };
        }).catch(e => {
          console.log('Failed to process', key);
        }).finally(() => {
          this.inProgress[key] = false;
        });
      });
    }
  }

  getIfRecentOrLoad(key, howRecent, deferable) {
    if (this.state[key] === undefined) {
      set(this.state, {
        [key]: null
      });
    }

    if (this.state[key] && (howRecent == null || Date.now() - this.state[key].timestamp < howRecent)) {
      return this.state[key].value;
    }

    this.start(key, deferable);
  }

  getNote(note) {
    if (!note) {
      return null;
    }

    return this.getIfRecentOrLoad(note, 500, () => this.client.get(note));
  }

  genLink() {
    const currentLink = window.location.href;
    return this.getIfRecentOrLoad(currentLink, null, () => this.links.shrink(currentLink));
  }

}, _temp), (_applyDecoratedDescriptor(_class.prototype, "start", [action], Object.getOwnPropertyDescriptor(_class.prototype, "start"), _class.prototype)), _class);

export const Notes = new _Notes();