import { ApiToken } from "./authorization.js";
let cachedUserInfo = null;
const userpool = 'https://sammonsio-notes.auth.us-east-1.amazoncognito.com';

const fetchUserInfo = () => {
  return fetch(`${userpool}/oauth2/userInfo`, {
    headers: new Headers({
      Authorization: `Bearer ${ApiToken()}`
    }),
    credentials: 'omit'
  }).then(res => {
    return res.json();
  });
};

export const UserInfo = {
  getUserInfo: async () => {
    if (cachedUserInfo == null) {
      cachedUserInfo = fetchUserInfo();
    }

    return cachedUserInfo;
  }
};