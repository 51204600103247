import { Config } from "../config/client-config.js";
import { ApiToken } from "./authorization.js";
export class LinkShrinkClient {
  async shrink(destination) {
    const headers = new Headers();
    headers.set('x-token', ApiToken());
    const result = await fetch(`${Config.linkShrinkDomain}`, {
      method: "POST",
      credentials: "omit",
      headers: headers,
      body: JSON.stringify({
        value: encodeURIComponent(destination)
      })
    });
    const body = await result.json();

    if (result.status == 200) {
      return 'https://link.sammons.io/s/' + body.value;
    } else {
      throw new Error(`Failed to shrink link ${destination}: ${body.message}`);
    }
  }

}