import { LoadingBarState } from "/note-taker/components/loading-bar.js";
import { NotesClient } from "/note-taker/clients/notes-client.js";
import { ListEditorState } from "/note-taker/components/list-editor.js";
export const ReloadCurrentNote = () => {
  console.log('reload');
  LoadingBarState.transient.enqueue(async () => {
    const value = await new NotesClient().get(`list-${ListEditorState.nav.noteName}`);

    if (value != null) {
      ListEditorState.transient.localContent = value;
    } else {
      ListEditorState.transient.localContent = {
        elements: []
      };
    }
  });
};